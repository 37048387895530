import { HTMLProps } from 'react';
import { getSignInEndpoint } from '../utils/getSignInEndpoint';

export const SignIn = (props: HTMLProps<HTMLAnchorElement>) => {
  return (
    <a {...props} href={getSignInEndpoint('login')}>
      Sign in
    </a>
  );
};
