import { useEffect, useState } from 'react';
import { usePersistentState } from './hooks/usePersistentState';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext, UserData } from './contexts/UserContext';
import { Buffer } from 'buffer';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import {
  KEY as ID_TOKEN_STORAGE_KEY,
  removeIdToken,
} from './storage/idTokenStorage';
import { areAllRequiredUserAttributesFilled } from './utils/UserAttributes';
import './App.css';
import { useCanonical } from './hooks/useCanonical';
import { reloadUserData } from './utils/reloadUserData';
import { getSignInEndpoint } from './utils/getSignInEndpoint';
import { Router } from './Router';
import { ErrorFallback } from './components/ErrorFallback';
import TagManager from 'react-gtm-module';
import './aws-rum-web';

function App() {
  const [idToken, setIdToken] = usePersistentState(ID_TOKEN_STORAGE_KEY);
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  // Store token
  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      const searchParams = new URLSearchParams(hash);
      const idToken = searchParams.get('id_token');
      if (idToken) {
        setIdToken(idToken);
      }
    }
  }, [setIdToken]);

  // Handle user data from the id token and from data from the endpoint
  useEffect(() => {
    if (!idToken) {
      return;
    }
    const userData = idToken
      ? JSON.parse(Buffer.from(idToken.split('.')[1], 'base64').toString())
      : {};

    reloadUserData(userData, setUserData).catch(() => {
      // Id token exists but it's not valid
      removeIdToken();
      window.location.replace(getSignInEndpoint('signup'));
    });
  }, [idToken]);

  // Handle redirect after login
  useEffect(() => {
    if (!idToken) {
      return;
    }
    (async () => {
      if (!userData) {
        return;
      }

      const notRedirectedPaths = [
        '/account',
        '/terms',
        '/privacy',
        '/contact-us',
        '/docs/*',
      ];
      if (notRedirectedPaths.includes(location.pathname)) {
        return;
      }

      if (!areAllRequiredUserAttributesFilled(userData)) {
        navigate('/account');
        return;
      }

      if (!userData.is_subscription_verified) {
        navigate('/pricing');
        return;
      }

      if (window.location.hash) {
        navigate('/analytics');
      }
    })();
  }, [userData, navigate]);

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_TAG_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
      });
    }
  }, []);

  return (
    <HelmetProvider>
      <UserContext.Provider
        value={{
          userData,
          onLogout: () => {
            removeIdToken();
            setUserData(undefined);
            navigate('/');
          },
          setUserData,
        }}
      >
        <Helmet>
          <link rel="canonical" href={useCanonical()} />
        </Helmet>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Router />
        </ErrorBoundary>
      </UserContext.Provider>
    </HelmetProvider>
  );
}

export default App;
