// Due to the "Required custom attributes are not supported currently", these fields are hardcoded
export const REQUIRED_USER_ATTRIBUTES = [
  'phone_number',
  'address',
  'given_name',
  'family_name',
  'website',
  'email',
  'custom:company_name',
  'custom:company_type',
  'custom:company_position',
  'custom:employees_num',
  'custom:country',
];

export const areAllRequiredUserAttributesFilled = (
  attributes: Record<string, any>
) => {
  for (const field of REQUIRED_USER_ATTRIBUTES) {
    if (!attributes[field]) {
      return false;
    }
  }
  return true;
};

export const getRequiredUserAttributes = (
  userAttributes: { Name: string; Value: string }[]
): Record<string, any> => {
  return userAttributes
    .filter((userAttribute) =>
      REQUIRED_USER_ATTRIBUTES.includes(userAttribute.Name)
    )
    .reduce(
      (attributes, current) => ({
        ...attributes,
        [current.Name]: current.Value,
      }),
      {}
    );
};
