import { AxiosInstance } from 'axios';

type AccountResponse = {
  UserAttributes: { Name: string; Value: string }[];
  Groups: {
    Description: string;
    GroupName: string;
  }[];
};

export class AccountRepository {
  constructor(private client: AxiosInstance) {}

  async getUserAttributes(): Promise<AccountResponse['UserAttributes']> {
    return (await this.getUserData())['UserAttributes'];
  }

  async getUserData(): Promise<AccountResponse> {
    return (await this.client.get('/account')).data;
  }

  async isSubscriptionVerified(): Promise<boolean> {
    return (await this.client.get('/account/subscription/verify')).data.success;
  }
}
