import { Link } from 'react-router-dom';
import { FooterContact } from './FooterContact';
import { FooterSocial } from './FooterSocial';
import { HTMLProps } from 'react';

export const Footer = ({ className }: HTMLProps<HTMLDivElement>) => (
  <footer className="px-2 lg:px-24 mt-12 py-12 bg-[#00052b] text-white font-extralight flex flex-col overflow-hidden items-center">
    <div
      className={
        'flex flex-wrap flex-col items-center sm:items-start sm:flex-row gap-6 sm:gap-4 lg:gap-6 justify-between container ' +
        className
      }
    >
      {/* Left part */}
      <div className="flex xl:flex-1 flex-col justify-start items-start gap-7 text-sm sm:text-base ">
        <FooterContact />
      </div>
      {/* Middle part */}
      <div className="flex xl:flex-1 flex-col justify-between md:justify-start items-start lg:text-lg w-fit whitespace-nowrap">
        <h4 className="lg:text-lg font-semibold leading-none tracking-[0.01em] uppercase">
          Sitemap
        </h4>
        <div className="grid grid-rows-3 gap-x-2 lg:gap-x-4 xl:gap-x-8 grid-flow-col py-2">
          <Link to="/">Home</Link>
          <Link to="/docs/model-requirements">Documentation</Link>
          <Link to="https://blog.wearitar.com">Blog</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
          <Link to="/contact-us">Contact us</Link>
        </div>
      </div>
      {/* Right part */}
      <div className="flex xl:flex-1 justify-center sm:justify-end items-center sm:items-start w-full sm:w-auto">
        <FooterSocial />
      </div>
      <h6 className="whitespace-nowrap w-full mt-6 md:-mt-8 text-center sm:text-start">
        &copy; 2022-{new Date().getFullYear()} Wearitar Corp. All rights
        reserved.
      </h6>
    </div>
  </footer>
);
