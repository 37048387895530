import { useInlineScript } from '../hooks/useInlineScript';
import { Navbar } from './Navbar';
import { Footer } from './Footer/Footer';
import ScrollToTop from 'react-scroll-to-top';
import { Container } from './Container';

export const Layout = ({ children }: { children: JSX.Element }) => {
  // Needs for navbar and custom js. Temporary solution, should be
  // removed when Jquery is purged from the project
  useInlineScript('/assets/js/custom.js');
  useInlineScript('//js.hs-scripts.com/40131026.js', {
    id: 'hs-script-loader',
  });

  return (
    <div className="flex min-h-screen flex-col justify-between">
      <Navbar className="fixed inset-x-0" />
      <Container className="flex flex-col gap-3 mt-20 p-3 bg-gray-50 rounded">
        {children}
      </Container>
      <ScrollToTop
        smooth
        className="bk-top"
        color="#1c5177"
        component={<div className="back-to-top">top</div>}
      />
      <Footer />
    </div>
  );
};
