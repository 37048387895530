import { HTMLProps } from 'react';
import { getSignInEndpoint } from '../utils/getSignInEndpoint';

export const SignUp = (props: HTMLProps<HTMLAnchorElement>) => {
  return (
    <a {...props} href={getSignInEndpoint('signup')}>
      Sign up
    </a>
  );
};
